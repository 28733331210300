@charset "UTF-8";

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function spanWidth($n) {
    @return $n * $fluidGridColumnWidth + $fluidGridGutterWidth * ($n - 1);
}

@function forceWidth($n) {
    @return $n * $fluidGridColumnWidth + $fluidGridGutterWidth * ($n - 1) !important;
}

@function offsetWidth($n) {
    @return $n * $fluidGridColumnWidth + $fluidGridGutterWidth * ($n + 1);
}

@function font-source-declaration($font-family, $file-path, $file-formats) {
    $src: ();

    $formats-map: (
        eot: "#{$file-path}.eot?#iefix" format("eot"),
        woff: "#{$file-path}.woff" format("woff"),
        ttf: "#{$file-path}.ttf" format("truetype"),
        svg: "#{$file-path}.svg##{$font-family}" format("svg")
    );

    @each $key, $values in $formats-map {
        @if contain($file-formats, $key) {
            $file-path: nth($values, 1);
            $font-format: nth($values, 2);

            $src: append($src, url($file-path) $font-format, comma);
        }
    }

    @return $src;
}

@function asset($type, $file, $url: false) {
    @if $url {
        @return url("#{$base-asset-path}\/#{$type}\/#{$file}");
    } @else {
        @return "#{$base-asset-path}\/#{$type}\/#{$file}";
    }
}
