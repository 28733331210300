/*
 * A series of helper classes to use arbitrarily. Only use a helper class if an
 * element/component doesn't already have a class to which you could apply this
 * styling, e.g. if you need to float '.main-nav' left then add 'float:left;' to
 * that ruleset as opposed to adding the '.left' class to the markup.
*/

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justified {
  text-align: justify !important;
}

.text-underline {
  text-decoration: underline !important;
}

.text-italic {
  font-style: italic !important;
}

.text-indent {
  padding-left: 2% !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.display-block {
  display: block !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
  *display: inline;
  zoom: 1; /*IE hack*/
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-text {
  vertical-align: text-bottom !important;
}

.back-link:before {
  content: "\00AB \a0";
}

.call-to-action {
  white-space: nowrap;

  &:after {
    content: "\a0 \00BB";
  }
}

.call-to-action-left {
  white-space: nowrap;

  &:before {
    content: "\00ab \a0";
  }
}

button.call-to-action, button.call-to-action-left {
  background: none;
  cursor: pointer;
  color: $linkColor;
  text-decoration: none;
  &:hover {
    color: $linkHoverColor;
    text-decoration: underline;
  }
  &:focus {
    color: $linkHoverColor;
    outline: 2px solid $linkHoverColor;
    text-decoration: underline;
  }
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

.aaDarkCadetGray {
    color: $darkCadetGray !important
}
.aaCadetGray {
    color: $cadetGray !important
}
.aaMediumGray {
  color: $mediumGray !important
}
.aaDarkGray {
    color: $darkGray !important;
}
.aaBlack {
    color: $black !important;
}

.aaBrandBlue {
  color: $brandBlue !important;
}

.aaLightBlue {
  color: $lightBlue !important;
}
.aaDetroitBlue {
    color: $detroitBlue !important;
}
.aaDarkBlue {
    color: $darkBlue !important;
}

.aaOrange {
  color: $orange !important;
}
.aaRed {
    color: $red !important;
}
.aaDarkRed {
  color: $darkRed !important;
}

.aaLuckyPurple {
    color: $luckyPurple !important;
}

.aaGreen {
  color: $green !important;
}

.aa-strong {
  font-weight: bold;
}

.is-error {
  @extend .aaDarkRed;

  &:empty {
    display: none;
  }
}

.is-info {
  @extend .aaGreen;
}

.is-warning {
  @extend .aaOrange;
}

.is-hidden,
.aa-display-none {
  display: none;
}

.hide-text {
  left: -9999px;
  position: absolute;
  top: -9999px;
}

.hidden-accessible {
  @include hidden-accessible();
}

.aa-clearfix {
  display: block;

  &:after {
    clear: both;
    content: '.';
    display: block;
    visibility: hidden;
    height: 0;
  }
}

.align-right {
  text-align: right !important;
}

.no-border {
  border: solid 0px transparent !important;
}

.no-border-left {
  border-left: 0 !important;
}

.no-border-bottom {
  border-bottom: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin: 0 !important;
}
.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-background {
  background: none;
}

.no-wrap {
  white-space: nowrap;
}

.no-padding-left {
  padding-left: 0px !important;
}

.padding-top {
  padding-top: 10px !important;
}

.padding-bottom {
  padding-bottom: 10px !important;
}

.padding-left {
  padding-left: 10px !important;
}

.padding-top-bottom {
  padding: $baseLineHeight 0;
}

.padding-left-right {
  padding: 0 $baseLineHeight;
}

.margin-left {
  margin-left: 18px;
}

.margin-all-small {
	margin: 10px;
}

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-right {
  margin-right: 10px;
}

.width-auto {
  width: auto;
}

.border-top {
  border-top: 1px solid $lightGray;
}

.border-bottom {
  border-bottom: 1px solid $lightGray;
}

.border-left {
  border-left: 1px solid $lightGray;
}

.border-right {
  border-right: 1px solid $lightGray;
}

.span-bg-superUltraLightGray {
  background-color: $superUltraLightGray;
  padding: 30px 20px 0px 20px;
}
.font-americanSans-medium{
	font-family: $sansFontFamilyMedium;
}

.font-americanSans-regular {
    font-family: $sansFontFamilyRegular;
}

.font-americanSans-light {
    font-family: $sansFontFamilyLight;
}
/* temporary helper class */
.font-medium {
  font-weight: bold;
  @include font-size(18);
  color: $darkBlue;
}

.terms-wrapper {
  @include theme-ultraLightGray;
  padding-right: 0;
  .customComponent {
    margin: -5px 0;
  }
}

.theme-ultraLightGray {
  @include theme-ultraLightGray;
}

.theme-superUltraLightGray {
  @include theme-superUltraLightGray;
}

@media print {
  .no-print {
    display: none;
  }

  .border-right {
    border-right: 1px solid $lightGray;
  }

  .border-left {
    border-left: 1px solid $lightGray;
  }

}
.affix {
    @include affix;
}
.covid-countries-list li {
    font-size: 16pt;
    line-height: 35px;
    font-family: AmericanSansLight;
}
.covid-countries-block {
    max-height: 400px;
    max-width: 800px;
}
#chinaQuestionDialog {
    min-width: 900px;
}
.canada-message-block {
    max-width: 900px;
    font-size: 16pt;
    line-height: 35px;
    font-family: AmericanSansLight;
}
.covid-disclaimer {
    font-family: AmericanSans;
    font-size: 14px;
    line-height: 20px;
    color: $cadetGray;
    margin-top: 16px;
}
