@mixin tooltip-theme($borderColor: $lightBlue, $titleColor: $gray05, $textColor: $gray05) {
    border-color: $borderColor;
    color: $textColor;

    .tooltip-title {
        color: $titleColor;
    }

    .tooltip-close {
        color: $borderColor;
    }

    .tooltip-arrow {
        border-top-color: $borderColor;
    }

    &.top .tooltip-arrow {
        border-bottom-color: $borderColor;
    }
}

[data-behavior~="tooltip"],
[data-behavior~="popover"] {
    display: inline-block;
}

fieldset[data-behavior~="tooltip"] {
    display: block;
}

.aa-tooltip {
    color: $darkGray;
    width: 100%;
    text-align: left;
    background-color: $white;
    position: absolute;
    z-index: 100;
    min-width: 100px;
    border: 1px solid $lightBlue;
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false) !important;
    @include font-small;
    @include box-shadow(2px 3px 4px 0 rgba(19, 19, 19, .3));
    &:focus {
        outline: none;
        border: 2px solid $brandBlue;
        .tooltip-arrow {
            border-top: 9px solid $brandBlue;
        }
        .tooltip-wrapper {
            margin: 9px;
        }
        .tooltip-close {
            top: 6px;
            right: 9px;
        }
    }

    &.round-all {
        @include border-radius(6px)
    }

    .tooltip-wrapper {
        margin: $baseLineHeight/2;
        overflow: hidden;
    }

    .tooltip-title {
        @extend .epsilon;
        color: $darkGray;
        font-weight: bold;
        margin: 0 0 $baseLineHeight/2;
    }

    .tooltip-subtitle {
        color: $brandBlue;
        font-weight: bold;
    }

    .tooltip-arrow {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $lightBlue;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -10px;

        .tooltip-arrow-inner {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid $white;
            height: 0;
            left: -10px;
            position: absolute;
            top: -12px;
            width: 0;
        }
    }

    .tooltip-close {
        position: absolute;
        top: 7px;
        right: 10px;
        &:focus {
            outline: none;
            [class*="icon-"] {
                display: inline;
            }
        }
    }

    &.top {
        .tooltip-arrow {
            border-top-color: transparent !important;
            border-bottom: 10px solid $lightBlue;
            top: -20px;
            bottom: auto;
            .tooltip-arrow-inner {
                border-top-color: transparent !important;
                border-bottom: 10px solid $white;
                top: -8px;
            }
        }
        &:focus .tooltip-arrow {
            border-bottom: 9px solid $brandBlue;
        }
    }

    &.left .tooltip-arrow {
        left: 0;
        margin: 0;
    }

    &.right .tooltip-arrow {
        right: 0;
        left: auto;
        margin: 0;
    }

    &.warning {
        @include tooltip-theme($orange, $orange);
    }

    &.error {
        @include tooltip-theme($darkRed, $darkRed, $darkRed);
    }

    &.success {
        @include tooltip-theme($green, $green);
    }
}

.tooltip-container {
    position: relative;
    a,
    button {
        position: absolute;
        top: 0;
        right: 0;
    }
    .label-text {
        padding-right: 15px;
    }
}
