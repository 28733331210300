@import "./variables";
.global-message {
    font-family: $sansFontFamilyRegular;
    padding: 0 50px;

    .container {
        padding: 0 0 10px;
    }

    .global-message-title {
        position: relative;
        font-family: $sansFontFamilyRegular;
        color: $darkGray;
        display: block;
        margin: .83em 0 0;

        @include font-size-line-height(24);

        &:before {
            display: block;
            position: absolute;
            left: -40px;
            top: 4px;
            height: 24px;
            width: 24px;
        }

        .icon-close {
            cursor: pointer;
            float: right;
            border: 0;
            background: none;

            &:before {
                color: $detroitBlue;
                font-size: 24px !important;
                vertical-align: baseline;
            }

            &:focus {
                outline: 2px solid $brandBlue;
            }
        }
    }

    .global-message-content {
        color: $darkGray;
        margin: 10px 0 0 0;

        @include font-size-line-height(16);

        .global-message-link {
            display: block;
            color: $detroitBlue;
            text-decoration: none;
            cursor: pointer;
            margin-bottom: 10px;

            &:hover {
                text-decoration: underline;
            }
        }

        .global-message-text {
            color: $detroitBlue;
            font-family: $sansFontFamilyMedium;
        }

        .global-message-button {
            @include aileron-button;
            display: inline-block;
            margin-bottom: 12px;
            min-height: 34px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.global-message--error {
        border-top: 5px solid $darkRed;
        background-color: $globalMessageErrorBGColor;

        .global-message-title:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23C30019' fill-rule='nonzero' d='M12 22.667c-5.867 0-10.667-4.8-10.667-10.667S6.133 1.333 12 1.333 22.667 6.133 22.667 12 17.867 22.667 12 22.667z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M13.333 16v2.667h-2.666V16h2.666zM14 5l-.667 9.333h-2.666L10 5h4z'/%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }

    &.global-message--warning {
        border-top: 5px solid $orange;
        background-color: $globalMessageWarningBGColor;

        .global-message-title:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23D14904' fill-rule='nonzero' d='M12.367 1.9l9.9 19.767c.1.2.033.4-.034.466-.033.067-.166.2-.366.2H2.1c-.2 0-.333-.133-.367-.2-.033-.066-.133-.233-.033-.466L11.6 1.9c.1-.2.3-.233.367-.233.1 0 .266.033.4.233z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M13.333 18v2.667h-2.666V18h2.666zm.334-9.333l-.334 8h-2.666l-.334-8h3.334z'/%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }

    &.global-message--success {
        border-top: 5px solid $green;
        background-color: $globalMessageSuccessBGColor;

        .global-message-title:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23008712' fill-rule='nonzero' d='M12 22.667c-5.867 0-10.667-4.8-10.667-10.667S6.133 1.333 12 1.333 22.667 6.133 22.667 12 17.867 22.667 12 22.667z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' stroke='%23FFF' stroke-width='2' d='M10.237 16.167L6 12.137 6.649 11.49 10.237 14.931 17.351 8.167 18 8.784z'/%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }

    &.global-message--info {
        border-top: 5px solid $brandBlue;
        background-color: $globalMessageInfoBGColor;

        .global-message-title:before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%230061AB' fill-rule='nonzero' d='M12 22.667c-5.867 0-10.667-4.8-10.667-10.667S6.133 1.333 12 1.333 22.667 6.133 22.667 12 17.867 22.667 12 22.667z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M13.667 9.667v9.666h-3.334V9.667h3.334zm0-4.334v3h-3.334v-3h3.334z'/%3E%3Cpath d='M0 0H24V24H0z'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }
}

@include respond-to(phone) {
    .global-message {
        padding: 0;

        .global-message-title {
            margin: 15px 30px 0 40px;
            font-family: $sansFontFamilyRegular;
            @include font-size-line-height(15);

            &:before {
                display: block;
                left: -25px;
                top: 1px;
                width: 15px;
                height: 15px;
            }
        }

        .global-message-content {
            padding: 10.5px 30px 10px 40px;
            margin: 0;
        }
    }
}
