.aa-dot-hazmat-icons {
    margin: 18px 2px;
    li {
		display: inline-block;
		width: 90px;
		vertical-align: top;
		text-align:center;
        a {
            display: block;
            text-align: center;
        }
    }
}

.aa-dot-hazmat-block-icons {
    li {
        display: inline-block;
        vertical-align: bottom;
        a {
            display: block;
            border: 1px solid $lightGray;
            border-radius: 10px;
            width: 142px;
            height: 127px;
            margin: 0 2px 2px;
            text-align: center;
            &:focus {
                border-radius: 10px;
                border: 2px solid;
                outline: none !important;
            }
        }

    }
}

.hr-95w {
    color: $lightGray;
    width: 95%;
    height: 1px;
}

.aa-dot-hazmat-block-icons-2x {
    a {
        width: 294px !important;
        height: 127px !important;
    }
}

.text-right-align {
    float: left;
    width: 68%;
    height: 38px;
    margin-top: 10px;
    text-align: right;
    font-family: $sansFontFamilyRegular;
    color: $darkGray;
}

.block-icons-text {
    font-family: $sansFontFamilyMedium;
    text-align: center;
    color: $brandBlue;
    line-height: 18px;
}

.block-icons-text-header {
    font-family: $sansFontFamilyLight;
    text-align: left;
    font-size: 30px;
    color: $darkBlue;
}

.block-icons-content-text {
    font-family: $sansFontFamilyRegular;
    text-align: left;
    @include font-size-line-height($h6-size);
    list-style-type: disc;
    color: $darkGray;

}

.extra-margin-left-15 {
    margin-left: 15px;
}

.svgicon {
    display: inline-block;
    height: 75px;
    width: 75px;
    margin: 10px 30px -10px;
}

.hazmat-active {
    @extend .aaOrange;
}

.aa-hazmat-icons-content {
    height:auto;
    min-height:150px;
    overflow: hidden;
    padding-right: 5px;
    ul:focus {
        @include focus-accessible;
    }
    li {
        list-style-type: square;
        list-style: outside;
        margin-left: 18px;
    }
}
.orderedlist {
    list-style: decimal outside none;
    margin: 5px 0 5px 50px;
}

@include respond-to(phone) {
	.aa-dot-hazmat-icons {
		li:nth-child(3n) {
		  margin-bottom: 50px;
		}
	}
}

#icon-powderMainContent,
#icon-powder {
    display:none;
}





