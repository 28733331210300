#main {
    min-height: 500px;
}

.note {
    margin: 0;
    color: $mediumGray;
    @include font-size(12);
}

.activityMemberSince {
    font-size: 14px;
    color: $darkGray;
    font-family: $sansFontFamilyLight;
    font-style: italic;
    margin-top: 10px;
}

.alt-header {
    color: $altHeadingsColor;
}

.site-banner {
    height: 100px;
    position: relative;
}

.site-logo {
    bottom: 16px;
    display: block;
    position: absolute;
    max-width: 100%;

    > img {
        display: block;
    }
}

[class|="site-content"] {
    position: relative;
    z-index: 20;
}

.site-content-hero {
    margin-top: -230px;
    z-index: 77;
}

#request-mileage-login label > input {
    margin-top: 0;
}

.oneworld-logo {
    position: absolute;
    top: 45px;
    right: 0;
}

.aa-news-and-offers {

    .box {
        min-height: 290px;
        margin-bottom: 10px;

        .box-head {
            font-size: 16px;
        }
    }

    .searchNearbyAirports {
        margin-top: 10px;
    }

    .emailSection {
        margin-top: 20px;
    }

    .btn_less {
        display: none;
    }
}

.aa-ad-holder {
    height: auto;
    padding: 10px;

    .aa-ad-heading {
        color: $brandBlue;
    }

    p {
        margin-bottom: 5px;
    }
}

.aa-bg-radial-ltgray {
    background-color: $ultraLightGray;
    @include background(radial-gradient($ultraLightGray, $lightGray 20em));
}

.aa-brand-blue {
    color: $brandBlue;
}

.coppa-message {
    float: right;
    width: 60%;
}

h5.small-header {
    color: $darkBlue;
    font-weight: normal;
}

.top-bottom-spacing {
    margin-top: 10px;
    margin-bottom: 10px;
}

.login-page-min-height {
    min-height: 405px;
}

.aa-top-spacing {
    margin-top: 20px;
}

.aa-busy {
    position: relative;

    > .aa-busy-module {
        z-index: 999999;
        border: medium none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
        cursor: wait;
        position: absolute;

        .aa-busy-bg {
            height: 100%;
            width: 100%;
            background-color: $white;
            @include opacity(.8);
        }

        .aa-busy-img {
            border: 0 none;
            cursor: wait;
            left: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            text-align: center;
            top: 45%;
            width: 100%;
            z-index: 1001;
        }

        .aa-logo {
            background-image: url("/content/images/chrome/rebrand/aa-flight-icon.png");
            background-position: center top;
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 100px;
            margin: 0 auto;
            width: 100px;
        }

        .aa-busy-logo {
            width: 81px;
            height: 64px;
            margin-bottom: 10px;
        }

        .aa-busy-spinner {
            background-image: url(/content/images/chrome/icons/loading.gif);
            background-repeat: no-repeat;
            height: 32px;
            width: 32px;
            vertical-align: middle;
            @include inline-block;
        }

        .aa-busy-spinner-app {
            border-radius: 50%;
            border-top: 3px solid $brandBlue;
            border-right: 3px solid transparent;
            width: 32px;
            height: 32px;
            -webkit-animation: spinnerCss .5s linear infinite;
            animation: spinnerCss .5s linear infinite;
            @include inline-block;
        }

        @-webkit-keyframes spinnerCss {

            0% {
                -webkit-transform: rotate(0deg);
            }

            100% {
                -webkit-transform: rotate(360deg);
            }
        }

        @keyframes spinnerCss {

            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
        .aa-busy-text {
            @include head4;
        }
    }
}

body.aa-busy {

    > .aa-busy-module {
        position: fixed;

        .aa-busy-img {
            top: 30%;
        }

        .aa-busy-logo {
            width: 122px;
            height: 96px;
            margin-bottom: 20px;
        }

        .aa-busy-text {

            @include head3;
        }
    }
}

.multiline-label-margintop {
    margin-top: -20px;
}

.securityQA-input {
    border: 0 !important;
    padding: 0 !important;
}

/*//Dropped TSA precheck support on 05/05/15. DP
//Leaving this for future integration: <span class="tsaPrecheck">TSA Pre<i></i>Reg</span>
//.tsaPrecheck {
//    font-family: "Lucida Sans Unicode","Lucida Grande",verdana,arial,Helvetica,sans-serif;
//    letter-spacing: -1.16667px;
//    i:before {
//        color:#64B84F;
//        content:"\2713";
//        @include font-size(16);
//        font-weight: bold;
//    }
//}*/
