label,
.label {
    display: inline-block;
    line-height: $baseLineHeight;
    margin: 5px 0;
    position: relative;
    width: 100%;

    &.is-error {
        @extend .is-error-icon;
    }

    .is-error & {
        @extend .aaDarkRed;
    }
}

.label-note {
    color: $gray03;
    font-size: .8em;
    line-height: 1;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    display: none;
}

input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

}

input[type="file"],
input[type="radio"],
input[type="checkbox"] {
    &:focus,
    &:active {
        @include box-shadow(none);
    }
}

textarea,
select,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
    @include border-radius(0);
    -webkit-appearance: none;
    background-color: $white;
    border: 1px solid;
    border-color: $gray;
    color: $gray05;
    display: inline-block;
    padding: 8px 9px 9px;
    text-align: left;
    @include font-size($baseFontSize);
    width: 100%;
    font-family: $baseFontFamily;
    vertical-align: middle;

    &[disabled] {
        opacity: 1;
    }

    &[readonly],
    &[disabled] {
        background-color: $ultraLightGray;
        border-color: transparent;
        color: $darkGray;

        &.active {
            background-color: $white;
            border-color: $gray;
            color: $gray05;
        }
    }

    &[readonly][disabled] + a.widget,
    &[readonly][disabled] + button.ui-datepicker-trigger {
        display: none;
    }

    .is-error &,
    .is-error &[readonly].active {
        border-color: $darkRed;
    }

    &.is-error {
        border-color: $darkRed;
    }

    label > & {
        display: block;
        margin-top: 5px;
    }

    &.is-error:before {
        content: "";
    }

    &.ui-autocomplete-input,
    &.aaDatePicker,
    &.aaRangeDatePicker {
        padding-right: 27px;
    }
}

select {
    padding-bottom: 7px;
    padding-right: 2px;
}

.readOnly {
    color: $bodyColor;
    margin-bottom: 5px;
    padding: 6px 9px 7px;
}

input[type="search"] {
    margin-top: 0;
    padding-right: 27px;
}

button {
    width: auto;
    overflow: visible;
    -webkit-appearance: none;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: 1px solid $brandBlue;
    border: 1px solid $brandBlue;

    .is-error & {
        border: 1px solid $brandBlue;
    }
}

button[disabled],
input[disabled],
select[disabled],
select[disabled] option,
select[disabled] optgroup,
textarea[disabled],
a[disabled],
a.disabled {
    @include box-shadow(none);
    background-color: $white;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    cursor: default;
    opacity: .4;
    pointer-events: none;
}

.lt-ie9 button[disabled],
.lt-ie9 input[type="submit"][disabled],
.lt-ie9 input[type="button"][disabled],
.lt-ie9 a[disabled] {
    filter: none;
    background-color: $lightGray;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 3px 0;
}

.radio input[type="radio"],
.checkbox input[type="checkbox"] {
    float: left;
    margin-left: -18px;
}

.controls {
    > li {
        overflow: hidden;
    }

    .radio:first-child,
    .checkbox:first-child {
        padding-top: 5px;

        &.tight {
            margin: 0;
            padding-top: 0;
        }
    }
}

.input-append {
    input {
        width: auto;
    }

    input[type="search"] {
        border-right-color: $white;

        &:focus {
            border-right-color: inherit;
        }
    }

    .btn {
        width: auto;
    }
}

@media (-webkit-min-device-pixel-ratio: 0) {
    select[size],
    select[multiple],
    select[multiple][size] {
        background-image: none;
        background-color: $white;
        padding-right: 3px;
    }

    select,
    select[size="0"],
    select[size="1"] {
        background-image: $blueDropdownTriangle;
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 10px;
        background-color: $white;
        padding-bottom: 8px;
        padding-right: 20px;
    }
}

.pillbox {
    @include border-radius(18px);
    background-color: $ultraLightGray;
    color: $darkBlue;
    display: block;
    float: left;
    font-family: $sansFontFamilyRegular;
    line-height: normal;
    margin: $baseLineHeight/2 0 0;
    overflow: hidden;
    padding: $baseLineHeight/5 $baseLineHeight/2;
    width: auto;
    cursor: pointer;

    &:hover {
        background-color: $brandBlue;
        color: $white;
    }

    &.selected {
        background-color: $brandBlue;
        color: $white;
    }

    & > input[type="radio"] {
        position: absolute;
        top: -20px;
    }

    + .pillbox {
        margin-left: $baseLineHeight/2;

        &.right {
            margin-left: 0;
            margin-right: $baseLineHeight/2;
        }
    }

    &.-no-margin-top {
        margin-top: 0;
    }
}

.customComponent {
    display: inline-block;

    input[type="hidden"] {
        @include hideAccessibleComponent();
    }

    input[type="checkbox"],
    input[type="radio"] {
        @include hideAccessibleComponent();

        & ~ label .control {
            background-color: $white;
            color: $white;
            height: 20px;
            width: 20px;
            display: inline-block;
            margin: -2px 1px 0;
            border: 1px solid $gray;
            -webkit-box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $lightGray;
            -moz-box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $lightGray;
            box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $lightGray;
            vertical-align: middle;
        }

        &:disabled ~ label {
            color: $mediumGray !important;
            background-color: $white;
            -moz-user-select: -moz-none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: default;
            pointer-events: none;
            .control {
                border: 1px solid $mediumGray !important;
                opacity: .4;
            }
        }

        & ~ label.is-error {
            &:before {
                content: none;
            }

            .control {
                border: 1px solid $darkRed;
            }
        }
    }

    input[type="checkbox"] {
        &:checked ~ label .control {
            -webkit-box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $brandBlue;
            -moz-box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $brandBlue;
            box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $brandBlue;
            background-color: $brandBlue;

            &::before {
                content: "";
                height: 4px;
                width: 8px;
                display: inline-block;
                border: 2px solid $white;
                border-top-style: none;
                border-right-style: none;
                margin: 4px;
                -ms-transform: rotate(-45deg); /* IE 9 */
                -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */

                transform: rotate(-45deg);
            }
        }

        &:hover ~ label .control,
        &:focus ~ label .control,
        & ~ label .control:hover,
        & ~ label .control:focus {
            outline: 1px solid $brandBlue;
            border: 1px solid $brandBlue;
        }

        &:hover ~ label.is-error .control,
        &:focus ~ label.is-error .control,
        ~ label.is-error .control:hover,
        ~ label.is-error .control:focus {
            border: 1px solid $brandBlue;
            outline: 1px solid $mediumGray;
        }

        & ~ label .control {
            margin-right: 10px;
        }
    }

    input[type="radio"] {
        & ~ label .control {
            border-radius: 50%;
            margin-right: 5px;
        }

        &:checked ~ label .control::before {
            content: "";
            height: 0;
            width: 0;
            margin: 3px;
            display: inline-block;
            border: 6px solid $brandBlue;
            box-shadow: 0 0 0 1px $brandBlue;
            border-radius: 50%;
            background-color: $brandBlue;
        }

        &:checked:hover ~ label .control,
        &:checked:focus ~ label .control,
        &:checked ~ label .control:hover,
        &:checked ~ label .control:focus {
            -webkit-box-shadow: 0 0 0 1px $brandBlue;
            -moz-box-shadow: 0 0 0 1px $brandBlue;
            box-shadow: 0 0 0 1px $brandBlue;
            border: 1px solid $brandBlue;
            outline: 1px solid $brandBlue;
        }

        &:hover ~ label .control,
        &:focus ~ label .control,
        & ~ label .control:hover,
        & ~ label .control:focus {
            border: 2px solid $brandBlue;
            outline: 1px solid $brandBlue;
        }

        &:checked ~ .pillbox,
        &:hover ~ .pillbox,
        &:focus ~ .pillbox,
        ~ .pillbox:hover {
            border: 1px solid $brandBlue;
            background-color: $brandBlue;
            color: $white;
            margin: 1px 10px 1px 0;
        }

        &:hover ~ .pillbox,
        &:focus ~ .pillbox,
        ~ .pillbox:hover {
            text-decoration: underline;
        }

        &:hover ~ label.is-error .control,
        &:focus ~ label.is-error .control,
        ~ label.is-error .control:hover,
        ~ label.is-error .control:focus {
            border: 2px solid $brandBlue;
        }

        &:checked {

            &:hover ~ label.is-error .control,
            &:focus ~ label.is-error .control,
            ~ label.is-error .control:hover,
            ~ label.is-error .control:focus {
                -webkit-box-shadow: 0 0 0 1px $brandBlue;
                -moz-box-shadow: 0 0 0 1px $brandBlue;
                box-shadow: 0 0 0 1px $brandBlue;
                border: 1px solid $brandBlue;
            }
        }
    }

    .pillbox {
        background-color: $ultraLightGray;
        color: $darkBlue;
        margin: 2px 11px 2px 1px;

        .control {
            display: none !important;
        }
    }

    .icon-arrow-right,
    .icon-arrow-left {
        margin: 0 8px;
        vertical-align: middle;
        color: $gray;

        &:before {
            @include font-size($icon-medium);
        }
    }

    &.-indent label {
        position: relative;
        padding-left: 30px;

        .control {
            left: 0;
            position: absolute;
            top: 3px;
        }
    }

    &.-xspace label {
        padding-left: 40px;
    }

    &.-verticalOffset {
        @include vertical-align(); // Requires a parent with height to work.
    }

    .lt-ie9 & {
        display: inline;
        position: relative;

        input[type="checkbox"],
        input[type="radio"] {
            position: static;
            clip: auto;
            padding: 0 !important;

            ~ label .control {
                display: none;
            }
        }

        label {
            display: inline;
            width: auto;
            &.pillbox {
                float: none;
            }
        }

        &.-indent {
            display: inherit;

            label {
                display: inline-block;
            }

            input {
                position: absolute;
                top: 5px;
                left: 5px;

                &:focus ~ label {
                    outline: 2px solid $focusColor;
                }
            }
        }
    }
}

[data-behavior~="custombox"] {
    &.is-error::before {
        content: " ";
    }
}

.custombox-wrapper {
    position: relative;
    overflow: hidden;

    input[type="radio"],
    input[type="checkbox"] {
        position: absolute;
        top: -30px;
        left: 0;
    }
}

.custombox {
    line-height: $baseLineHeight;
    margin: 5px 0;
    padding-left: 30px;
    width: auto;
    min-height: 18px;

    .control {
        background-color: $white;
        color: $white;
        float: left;
        height: 20px;
        margin: 0 0 0 -29px;
        width: 20px;
        border: 1px solid $gray;
        box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $lightGray;

        &.radio {
            @include border-radius(10px);
        }

        &.checkbox {
            padding: 0;
            text-align: left;

            &::before {
                content: "\10e049";
                font-size: 14px;
                line-height: 18px;
                font-family: "American Icons";
                padding-left: 2px;
            }
        }

        &:hover {
            border: 1px solid $darkGray;
        }

        + .control {
            display: none;
        }
    }

    &.is-error {
        &::before {
            content: " ";
        }

        .control {
            border: 1px solid $darkRed;
        }
    }

    &.js-focus .control {
        border: 1px solid $darkGray;
    }

    &.selected .control {
        background-color: $brandBlue;

        &.checkbox {
            box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $brandBlue;
        }

        &.radio {
            box-shadow: inset 0 0 0 2px $white, inset 0 0 0 3px $white;
        }
    }

    &.disabled {
        @include opacity(.5);

        .control:hover {
            border: 1px solid $gray;
        }
    }

    /*--- Override classes ---*/
    &.-xspace {
        padding-left: 40px;

        .control {
            margin-left: -39px;
        }
    }

    &.-right {
        padding-left: 0;
        padding-right: 30px;

        .control {
            float: right;
            margin-left: 0;
            margin-right: -29px;
        }

        &.-xspace {
            padding-right: 40px;

            .control {
                margin-right: -39px;
            }
        }
    }

    /*--- When label size is increased to DELTA need to adjust custombox vertical spacing ---*/
    &.-delta .control {
        margin-top: 2px;
    }
}

.form-column {
    float: left;
    padding: 9px 9px 0;
    position: relative;
    width: 25%;

    &.callout {
        position: absolute;
        background: transparent url(/content/images/chrome/rebrand/shadow-vertical-150.png) left 50% no-repeat;
        background-size: auto 100%;
        padding-left: 18px;
        top: 52px;
        right: 0;

        .call-to-action {
            display: block;
            margin-top: 9px;
        }
    }
}

.form-header {
    @include head4;
    color: $gray03;
    padding: 9px 9px 0;

    .callout & {
        margin-bottom: 9px;
        padding: 0;
    }
}

.form-header-small {
    @include head5;
}

.widget {
    top: 34px;
    position: absolute;
    right: 9px;

    [class^="icon"]:before {
        @include font-size(18);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.inline {
    margin: 5px 0;

    > .value {
        color: $gray03;
        left: 12px;
        position: absolute;
        top: 5px;

        &.hidden {
            display: none;
        }
    }

    &.active {
        > .value {
            color: $gray02;
        }
    }
}

.is-error-icon {
    @extend .aaDarkRed;

    &:before {
        font-family: "American Icons";
        @include font-size(13);
        @extend .icon-error:before;
        vertical-align: top;
    }
}

.is-error .label,
.label.is-error {
    @extend .is-error-icon;

    &:before {
        margin-right: 3px;
    }
}

[class*="input-group"] {
    margin-bottom: 5px;

    label {
        display: inline-block;
        vertical-align: middle;

        &.control {
            height: 28px;
            line-height: 28px;
            vertical-align: bottom;
        }

        > .value {
            @include hidden-accessible();
        }

        &.is-error:before {
            content: "";
        }
    }

    .label {
        margin-bottom: 0;
    }

    legend {
        display: none;
    }

    .legend {
        @extend .label;
    }

    .row,
    .row-noBreak {
        select,
        input {
            margin: 0;
        }
    }
}

.input-group-phone {
    input.area-code,
    input.country-code {
        width: 42px;
    }
}

.input-group-controls {
    label {
        margin-bottom: 7px;
    }
}

[class*="-nolegend"] {
    label {
        margin: 5px 0;
    }

    input[type="text"] {
        margin-top: inherit;
    }
}

.row-form {
    + .row-form {
        @include margin("top");
    }

    label {
        margin: 0;

        &.custombox {
            margin-bottom: -5px;
        }
    }

    .label {
        margin: 5px 0 0;
    }

    [class*="input-group"] {
        margin: 0;

        .legend {
            margin: 0 0 5px;
        }
    }

    .is-error,
    .is-info,
    .is-warning {
        &.-message {
            margin: 5px 0 0;
        }
    }

    p + & {
        @include margin("top");
    }
}

.input-container {
    position: relative;

    input,
    select,
    textarea {
        border-color: $lightGray;
        @include border-radius(3px);
        line-height: 18px;
        margin-top: 0;
        padding-bottom: 7px;
        padding-left: 9px;
        padding-top: 25px;

        &.no-label-text {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }

    select {
        color: $brandBlue;
    }

    label {
        color: $mediumGray;
        position: absolute;
        left: 9px;
        top: 6px;
        line-height: 18px;
        width: auto;
        margin: 0;
    }

    input,
    select,
    textarea {
        &.input-container__input--error {

            &:not(:focus) {
                border-color: $darkRed;
            }

            & + label {
                color: $darkRed;
                left: 23px;

                &.no-label-text {
                    &::before {
                        content: none;
                    }
                }

                &::before {
                    bottom: 0;
                    color: $darkRed;
                    content: "\10e03d";
                    display: block;
                    font-family: "American Icons";
                    font-size: 12px;
                    height: 20px;
                    left: -15px;
                    margin-bottom: auto;
                    margin-top: auto;
                    position: absolute;

                }
            }

            & ~ .input-container__error {
                display: block;
            }

            &.no-text {
                padding-top: 16px;
                padding-bottom: 16px;
            }
        }
    }

    .input-container__error {
        color: $darkRed;
        display: none;
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
    }
}

@include respond-to(phone) {
    .input-container {
        margin-bottom: 16px;

        .input-container__error {
            margin-top: 9px;
        }
    }

    .section {
        .section + .section {
            margin-top: 0;
            padding-top: 0;
            border: none;
        }
    }

    hr {
        &.hr-full-phone {
            margin-left: -10px;
            margin-right: -10px;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
}
