.v4 {
    #main {
        min-height: 500px;
    }

    .container {
        width: 100%;
        max-width: $gridTotal;
        .lt-ie9 & {
            width: $gridTotal !important;
        }
    }

    header {
        .container {
            max-width: 1100px;
            margin-bottom: -3px;
        }
    }
}
