.menu {
    @include clearfix;
    @include head3;
    font-family: $sansFontFamilyRegular;
}

.menu-item {
    display: block;
    float: left;

    .menu-meganav & {
        margin-top: -4px;
        padding: 4px 4px 0;
        overflow: hidden;
    }

    & a {
        cursor: pointer;
        display: block;
        outline: none;
        padding: 4px 24px 18px;
        text-decoration: none;

        &:hover {
            background-color: transparent;
            color: $lightBlue;
        }

        &.is-current {
            color: $lightBlue;
            background-color: white;
            @include box-shadow(0 -1px 9px 1px $gray02);
        }

        // Locale-specific spacing to accomodate translation
        #aa-lang-es #site-nav &,
        #aa-lang-pt #site-nav & {
            padding-left: 22px;
            padding-right: 22px;
        }
    }

    &.is-current a {
        background-color: white;
        color: $brandBlue;
    }

}

.menu-utility {
    @include clearfix;
    font-size: 11px;
    line-height: 27px;
}

.menu-item-utility {
    display: block;
    float: left;
    margin-left: 3px;

    > a {
        margin-top: 2px;
        padding: 0 6px;
        color: $mediumGray;
        display: block;
        &:focus,
        &:hover {
            color: $linkHoverColor;
        }
    }
}

.menu-widget {
    background-color: $gray01;
    @include shadow-up;

    & > .menu-item {
        margin: 0;
        padding: 0;
    }

    a {
        color: #556a77;
        text-align: center;
        padding-top: 9px;
        padding-bottom: 9px;
    }
}

.menu-widget-flat {
    background: white;
    border-bottom: 1px solid $gray03;
    margin-top: 9px;
    padding: 0 9px;

    a {
        color: $brandBlue;
    }

    & > .menu-item {
        margin-top: 1px;
        margin-bottom: -1px;
        padding: 0 1px;
    }

    & > .is-current {
        border: 1px solid $gray03;
        border-bottom: none;
        margin-bottom: -1px;
        margin-top: 0;
        padding: 0;

        a {
            color: $lightBlue;
        }
    }
}

.menu-widget-multiline {
    .menu-item {
        @include font-size(18);
        line-height: $baseLineHeight;
    }
}

.menu-widget-small {
    .menu-item {
        @include font-size(12);
    }
    a {
        padding: 0 8px;
    }
}

.menu-item-sub {
    display: block;
    @extend .epsilon;
    font-family: $baseFontFamily;
}

.tab-content {
    margin: $baseLineHeight 0;
}

.site-widgets {
    background-color: white;
    color: $gray03;
    height: 216px;
    overflow: hidden;
}
