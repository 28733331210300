@each $family, $fonts in $font-family-list {
    @each $font, $rules in $fonts {
        @include font-face(
            $family,
            asset(if($family == 'American Icons', 'icons', 'american-v2'), $font),
            unquote(map-get($rules, "weight")),
            unquote(map-get($rules, "style")),
            $font-face-types
        );
    }
}
