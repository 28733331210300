$block: 'notification';
$heading: 'h1,h2,h3,h4,h5,h6';

.#{$block} {
	display: table;
	width: 100%;
	padding: 10px;
	margin-bottom: 20px;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background-color: #ffffff !important;
    box-shadow: 0 2px 4px 0 rgba(54, 73, 90, 0.2) !important;
	@include border-radius(5px);

	&__icon{
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		width: 58px;
		span{
			&:before{
				font-family: 'American Icons';
				color: $white;
				@include font-size-line-height(38);
			    -webkit-font-smoothing: antialiased;
			}
		}
	}

	&__body{
		margin: 10px 10px 10px 15px;
        font-family: AmericanSans, Helvetica, Arial, sans-serif;
        position: relative;
	}

	#{$heading}{
		font-family:$sansFontFamilyLight;
		@include font-size-line-height($h3-size);
		margin-bottom: 0;
		@media screen and (max-width: 767px){
			@include font-size-line-height($med-size);
		}
	}

	&--error{
		.#{$block}__icon{
			background-color: $darkRed;
            border-radius: 5px 0 0 5px;
            width: 45px;
			span::before{
                font-size: 25px;
				content: "\10e03d";
			}
		}
		#{$heading}{
			color: $darkRed;
		}
	}

	&--warning{
		.#{$block}__icon{
			background-color: $orange;
            border-radius: 5px 0 0 5px;
            width: 45px;
			span::before{
                font-size: 25px;
				content: "\10e03e";
			}
		}
		#{$heading}{
			color: $orange;
		}
	}

	&--info{ //This is the equivalent of "success" in the new core styles
		.#{$block}__icon{
			background-color: $green;
            border-radius: 5px 0 0 5px;
            width: 45px;
			span::before{
                font-size: 25px;
				content: "\10e03c";
			}
		}
		#{$heading}{
			color: $green;
		}
	}

	&--general{ //This is the equivalent of "info" in the new core styles
        .#{$block}__icon{
            background-color: $darkBlue;
            border-radius: 5px 0 0 5px;
            width: 45px;
            span::before{
                font-size: 25px;
                content: "\10e03c";
            }
        }
        #{$heading}{
            color: $darkBlue;
        }
    }

	&--small{
		.#{$block}__icon{
			width: 62px;
		}
		.#{$block}__body{
			margin: 0 0 0 15px;
		}
		span::before{
			@include font-size-line-height(21);
		}
		#{$heading}{
			font-family:$sansFontFamilyRegular;
			@include font-size-line-height($h5-size);
		}
		p{
			font-family:$sansFontFamilyRegular;
			@include font-size-line-height($h6-size);
			margin-bottom: 0;
		}
	}
}

@media screen and (min-width: 768px) {
    .#{$block} {
        box-shadow: 0 0 4px #9da6ab !important;
        .#{$block}__icon{
            width: 64px;
            span::before{
                font-size: 38px;
            }
        }
    }
}

@include respond-to(phone) {
    .#{$block} {
        .#{$block}__body{
            margin: 0 0 0 15px;
        }
    }
}


$collapse-block: 'collapse-notification';

.#{$collapse-block} {
    display: table;
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    margin-top: 16px;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background-color: #ffffff !important;
    border-style: solid;
    border-radius: 5px;
    border-width: 1px 1px 1px 0.5rem;

    @include border-radius(5px);

    &__icon{
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 38px;
        span{
            &:before{
                font-family: 'American Icons';
                color: $white;
                @include font-size-line-height(25);
                -webkit-font-smoothing: antialiased;
            }
        }
    }

    &__body{
        margin: 10px 10px 5px 15px;
        font-family: AmericanSans, Helvetica, Arial, sans-serif;
        position: relative;
    }

    #{$heading}{
        font-family: AmericanSans;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #36495a;
        margin-bottom: 0;
    }

    &--error{
        .#{$collapse-block}__icon{
            //background-color: $darkRed;
            border-radius: 5px 0 0 5px;
            width: 45px;
            span::before{
                font-size: 25px;
                content: "\10e03d";
                color: $darkRed;
            }
        }

        border-color: $darkRed;
    }

    &--warning{
        .#{$collapse-block}__icon{
            //background-color: $orange;
            border-radius: 5px 0 0 5px;
            width: 45px;
            span::before{
                font-size: 25px;
                content: "\10e03e";
                color: $orange;
            }
        }

        border-color: $orange;
    }

    &--info{ //This is the equivalent of "success" in the new core styles
        .#{$collapse-block}__icon{
            //background-color: $green;
            border-radius: 5px 0 0 5px;
            width: 45px;
            span::before{
                font-size: 25px;
                content: "\10e03c";
                color: $green;
            }
        }

        border-color: $green;
    }

    &--general{ //This is the equivalent of "info" in the new core styles
        .#{$collapse-block}__icon{
            //background-color: $darkBlue;
            border-radius: 5px 0 0 5px;
            width: 45px;
            span::before{
                font-size: 25px;
                content: "\10e03c";
                color: $darkBlue;
            }
        }

        border-color: $darkBlue;
    }

    &--small{
        .#{$collapse-block}__icon{
            width: 62px;
        }
        .#{$collapse-block}__body{
            margin: 0 0 0 15px;
        }
        span::before{
            @include font-size-line-height(21);
        }
        #{$heading}{
            font-family:$sansFontFamilyRegular;
            @include font-size-line-height($h5-size);
        }
        p{
            font-family:$sansFontFamilyRegular;
            @include font-size-line-height($h6-size);
            margin-bottom: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .#{$collapse-block} {
        .#{$collapse-block}__icon{
            width: 38px;
            span::before{
                font-size: 25px;
            }
        }
    }
}

@include respond-to(phone) {
    .#{$collapse-block} {
        .#{$collapse-block}__body{
            margin: 0 0 0 15px;
        }
    }
}

